import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TNameResponse } from '@models/authTypes';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class FileService {
  constructor(private http: HttpClient) {}

  saveFile(formData: FormData): Observable<TNameResponse | null> {
    return this.http.post<TNameResponse>(
      `${environment.getApiVersionUrl('files/save')}`,
      formData,
    );
  }
}
