import { declensionWord } from '@utils/declension-words';

export const imageExtensions = ['jpeg', 'jpg', 'heic', 'png'];
export const videoExtensions = ['mp4', 'mov'];

export const availableImages = '.jpeg, .jpg, .heic, .png';
export const availableVideos = '.mp4, .mov';

export const availableFiles = 'jpeg, jpg, heic, png, mp4, mov';

// Максимальный размер изображения - 10Мб
export const MAX_IMAGE_SIZE = 1024 * 1024 * 10;
export const ERROR_MAX_IMAGE_SIZE = 'Максимальный размер изображения 10Мб';

// Максимальный размер видео - 150Мб
export const MAX_VIDEO_SIZE = 1024 * 1024 * 150;
export const ERROR_MAX_VIDEO_SIZE = 'Максимальный размер видео 150Мб';

export const ERROR_MAX_VIDEO_COUNT = 'Разрешена загрузка только одного видео';

// Максимальное количество файлов в объявлении
export const MAX_FILES_COUNT = 10;
export const ERROR_MAX_FILES_COUNT = `Разрешена загрузка не более ${MAX_FILES_COUNT} ${declensionWord(
  {
    expressions: ['объекта', 'объектов', 'объектов'],
    amount: MAX_FILES_COUNT,
  },
)}`;
