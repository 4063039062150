@if (!textAreaMode()) {
  <input
    type="text"
    [placeholder]="placeholder()"
    (blur)="blurFunction()"
    [formControl]="control"
    [matAutocomplete]="addressAutocomplete"
    [ngClass]="{
      'address-input': true,
      error: error() && showError() && !addressAutocomplete.isOpen,
      opened: addressAutocomplete.isOpen,
    }"
  />
}

@if (textAreaMode()) {
  <textarea
    type="text"
    [placeholder]="placeholder()"
    (blur)="blurFunction()"
    [formControl]="control"
    [matAutocomplete]="addressAutocomplete"
    [ngClass]="{
      'address-input': true,
      error: error() && showError() && !addressAutocomplete.isOpen,
      opened: addressAutocomplete.isOpen,
    }"
  ></textarea>
}

<mat-autocomplete
  #addressAutocomplete="matAutocomplete"
  class="autocomplete-address-list"
>
  @if (addressList().length > 0) {
    @for (address of addressList(); let index = $index; track index) {
      <mat-option
        [value]="
          address.address
            ? address.region + ', ' + address.address
            : address.region
        "
        class="autocomplete-address-item"
        >{{
          address.address
            ? address.region + ", " + address.address
            : address.region
        }}</mat-option
      >
    }
  } @else {
    <div
      *ngIf="!loading() && !addressesRequested()"
      class="autocomplete-address-hint"
    >
      Введите адрес
    </div>
    <div
      *ngIf="!loading() && addressesRequested()"
      class="autocomplete-address-hint"
    >
      По вашему запросу ничего не найдено
    </div>
    <div *ngIf="loading()" class="loader-wrapper">
      <app-loader class="loader-component" />
    </div>
    <mat-option class="autocomplete-address-item empty"></mat-option>
  }
</mat-autocomplete>
